export function stripHtml(source = '', { ignoreTags = [] } = {}) {
  let regex = /(<([^>]+)>)/ig;
  return source.replace(regex, '')
}

export function stringContainsHtml(str = '') { 
  return /<\/?[a-z][\s\S]*>/i.test(str)
}

export function stringShortner({ str = '', nrCharacters = 0, useWordBoundary = false, renderHtmlHellip = false } = {}) {
    if (nrCharacters === 0) {
      return str
    }
    let text = str.toString()
    let toLong = text.length > nrCharacters
    let newText = toLong ? text.substr(0, nrCharacters - 1) : text

    if (useWordBoundary === true) {
      let arrWords = text.split(' ')
      toLong = arrWords.length >= nrCharacters
      newText = text
      if (toLong === true) {
        arrWords.splice(nrCharacters, arrWords.length - nrCharacters)
        newText = arrWords.join(' ')
      }
    }
  
    if (toLong) {
      newText += renderHtmlHellip ? '&hellip;' : '...'
    }
  
    return newText
  }

export function nl2br(source = '') {
  if (source != null) {
    return source.replace(/\n/g, '<br />')
  }
  return str}


export function br2nl(source = ''){
  if (source != null) {
    return source.replace(/<br>/g, '<br />')
  }
  return source
}

export function convertToSlug(str = '') {
  if (!str) { return str }
  return str
    .toLowerCase() // converts to lower case
    .normalize("NFD") // normalizes accented characters (For example: á --> a)
    .replace(".com", " ") // replaces .com with a space
    .replace("&", "and") // replaces & with 'and'
    .replace(/[^\w -]+/g, "") // removes all special characters except spaces and hyphens
    .replace(/ +/g, "-") // condenses consecutive spaces to a single hyphen
    .replace(/-+/g, "-"); // condenses consecutive hyphens to a single hyphen
}