<template>
  <div v-if="article" class="articleDetail">
    <div class="block mb-6">
      <h1 class="title is-size-4-mobile is-size-3-touch is-size-2-desktop">
        {{ article.Title }}
      </h1>
      <div class="authorDetail is-hidden-tablet">
        <span class="mr-3">{{ article.Author }}</span>
        <span class="mr-3">{{ articleDate }}</span>
      </div>
    </div>

    <div class="columns is-multiline">
      <!-- Column article text -->
      <div class="column-article-text column is-two-thirds">
        <ImageViewer v-if="article.ImageUrl" :aspectRatio="3 / 2" :imageSrc="article.ImageUrl" :itemId="article.Id" :itemName="article.Title" type="article" class="is-16by9 mb-4" />
        <div v-if="shortText" class="content block has-text-weight-semibold" v-html="shortText"></div>

        <div v-if="fullText" v-html="fullText" class="block content mb-6"></div>
      </div>

      <!-- Column sources -->
      <div class="column-sources column is-one-third">
        <div class="box-author mb-6 pb-6">
          <div class="mb-4">
            <strong class="has-text-weight-bold">Published by:</strong><br />
            {{ article.Author }}
          </div>
          <div class="mb-4">
            <strong class="has-text-weight-bold">Published on:</strong><br />
            <Tags v-if="article.Status !== 'Published' &&
              Object.keys(publishState).length !== 0
              " :nrOfTags="0" :tags="publishState.text" :tagCssClass="publishState.tagCssClass" class="is-inline-flex" />
            <template v-else>
              {{ articleDate }}
            </template>
          </div>

          <div v-if="article.Source" class="mb-4">
            <div class="source">
              <hr class="mb-2" />
              <strong class="has-text-weight-bold">Sources:</strong><br />
              {{ article.Source }}
            </div>
          </div>

          <div v-if="article.EnableComments" class="mb-4">
            <strong class="has-text-weight-bold">Comments:</strong><br />
            Publisher allow users to post a comment.
          </div>

          <strong class="has-text-weight-bold">Tags:</strong>
          <Tags :tags="article.Tags" :nrOfTags="0" class="mt-2" />
        </div>

        <template v-if="Object.keys(articleSources).length !== 0">
          <div class="title is-4 mb-4">External sources</div>
          <div v-for="(sourceList, key, index) in articleSources" :key="'s' + index">
            <div class="block-source" :class="{ 'mt-6': index !== 0 }">
              <div v-for="(sourceItem, sIndex) in sourceList" :key="'s' + index + sourceItem.Id" :class="{
                'mt-1': sIndex !== 0 && key === 'Link',
                'mt-5': sIndex !== 0 && key === 'Video',
              }" class="row-source">
                <LinkIcon v-if="key === 'Link'" :text="sourceItem.Text" type="Link" :newWindow="true" :to="sourceItem.Link" iconCssClass="is-small" class="has-icon-background" />
                <div v-if="key === 'Video'">
                  <component v-bind:is="getComponentType(sourceItem)" :src="sourceItem.Link" class="mb-2"></component>
                  <div class="has-text-weight-bold">
                    {{ sourceItem.Text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import articleProvider from '@/providers/article'
import { nl2br, stringContainsHtml, stripHtml } from '@/utils/stringHelper'
import parseISO from 'date-fns/esm/parseISO/index.js'
import intlFormat from 'date-fns/intlFormat'

export default {
  components: {
    ImageViewer: () => import('@/components/UI/ImageViewer'),
    Tags: () => import('@/components/UI/Tags'),
  },

  props: {
    articleKey: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      article: null,
      dateFormat: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      },
      isLoading: false,
    }
  },

  computed: {
    articleDate() {
      if (this.article) {
        return intlFormat(parseISO(this.article.Date), this.dateFormat, {
          locale: this.$i18n.locale,
        })
      }
      return ''
    },

    shortText() {
      if (this.article) {
        return nl2br(stripHtml(this.article.Short.trim(), true))
      }
      return ''
    },

    fullText() {
      if (this.article) {
        if (stringContainsHtml(this.article.Text)) {
          return this.article.Text
        }
        return nl2br(stripHtml(this.article.Text.trim(), true))
      }
      return ''
    },

    articleSources() {
      if (this.article) return {}

      let output = {}

      this.article.Links.forEach((linkItem) => {
        let TYPE =
          linkItem.LinkType === 'ExternalUrl' ||
            linkItem.LinkType === 'InternalUrl'
            ? 'Link'
            : 'Video'
        if (!output.hasOwnProperty(TYPE)) {
          output[TYPE] = []
        }
        output[TYPE].push(linkItem)
      })

      output = sortAscendingObject(output)

      return output
    },
  },

  created() {
    this.getArticleByKey()
  },

  methods: {
    filterComments(parentId) {
      let comments = []
      if (this.mArticle) {
        comments = this.mArticle.Comments.filter((c) => c.ParentId === parentId)
      }
      return comments
    },

    getArticleByKey() {
      if (this.isLoading) {
        return
      }

      this.isLoading = true

      articleProvider.methods
        .getArticleByKey(this.articleKey)
        .then((response) => {
          if (response.status === 200) {
            this.article = response.data
          }
        })
        .catch((err) => { })
    },
  },
}
</script>

<style></style>
