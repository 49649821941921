import axios from 'axios'
import store from '@/store'

export default {
  methods: {
    //#region GET calls
    async getArticleById(articleId) {
      return await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/article/${articleId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
    },

    async getArticleByKey(key) {
      return await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/article/${key}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
    },

    async getChannelArticles({ channelId, searchTerm = '', page = 1, itemsPerPage = 25, type = 'None' } = {}) {
      return await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/article/channel/${channelId}`,
        {
          params: {
            q: searchTerm,
            at: type,
            page,
            itemsPerPage,
          },

          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
    },
    //#endregion GET


    //#region POST calls
    createArticle: async (article) => {
      return await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/article`,
        article,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        })
    },

    async createArticleLink(link) {
      return await axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/v1/article/link`,
        link,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        })
    },
    //#endregion POST


    //#region PUT calls
    updateArticle: async (article) => {
      return await axios.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/article/${article.Id}`, article,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
    },

    publishArticle: async (article) => {
      return await axios.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/article/${article.Id}/publish`, null,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
    },

    updateArticleLink: async (link) => {
      return await axios.put(`${process.env.VUE_APP_API_BASE_URL}/api/v1/article/${link.ArticleId}/link/${link.Id}`, link,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      )
    },
    //#endregion PUT


    //#region DELETE calls
    deleteArticleLink: async (articleId, linkId) => {
      return await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/article/${articleId}/link/${linkId}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        })
    },

    deleteArticle: async (key) => {
      return await axios.delete(`${process.env.VUE_APP_API_BASE_URL}/api/v1/article/${key}`,
        {
          headers: {
            'Content-type': 'application/json',
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        })
    },
    //#endregion DELETE
  }
}